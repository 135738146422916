import React from 'react';
import { useSelector } from 'react-redux';
import { groupActionClick } from '@wix/bi-logger-groups/v2';
import { useBi, useTranslation } from '@wix/yoshi-flow-editor';

import type { TPAComponentProps } from 'wix-ui-tpa/dist/cssVars/types/types';

import { useHref } from 'router/react';
import { useController } from 'common/context/controller';

import {
  selectGroupSlug,
  selectHasAdminRole,
  selectIsGroupSecret,
  selectIsJoinedGroupMember,
} from 'store/selectors';

import { More as MoreIcon } from '@wix/wix-ui-icons-common/on-stage';
import { Menu, MenuItem } from 'wui/Menu';
import { IconButton } from 'wui/IconButton';
import { ShareDialog } from 'wui/ShareDialog';

import { GroupSettings } from './GroupSettings';
import { NotificationSettings } from './NotificationSettings';

import { DeleteGroupDialog } from './DeleteGroupDialog';
import { GroupAction } from './GroupAction';

import { GROUP_ACTIONS_THREE_DOTS } from './dataHooks';
import classes from './GroupActions.scss';

interface GroupActionsProps extends TPAComponentProps {
  groupId: string;
}

export const GroupActions: React.FC<GroupActionsProps> = (props) => {
  const { groupId } = props;
  const bi = useBi();
  const { t } = useTranslation();
  const { application$ } = useController();
  const [openedDialog, setOpenedDialog] = React.useState<GroupAction | null>(
    null,
  );

  const slug = useSelector(selectGroupSlug(groupId));
  const isJoined = useSelector(selectIsJoinedGroupMember(groupId));
  const isAdmin = useSelector(selectHasAdminRole(groupId));
  const isSecret = useSelector(selectIsGroupSecret(groupId));

  const groupUrl = useHref(
    'group.discussion.feed',
    { slug },
    { absolute: true },
  );

  if (!isJoined) {
    return null;
  }

  return (
    <>
      <Menu
        element={
          <IconButton
            icon={<MoreIcon />}
            className={classes.iconButton}
            data-hook={GROUP_ACTIONS_THREE_DOTS}
            aria-label={t('groups-web.a11y.more-actions')}
          />
        }
      >
        {isJoined && (
          <MenuItem
            onClick={openNotificationSettingsDialog}
            content={t(GroupAction.NOTIFICATION_SETTINGS)}
          />
        )}
        {isAdmin && (
          <MenuItem
            onClick={openGroupSettingsDialog}
            content={t(GroupAction.GROUP_SETTINGS)}
          />
        )}
        {!isSecret && (
          <MenuItem
            onClick={handleOpenShareDialog}
            content={t(GroupAction.SHARE)}
          />
        )}
        {isJoined && (
          <MenuItem
            onClick={handleOpenLeaveDialog}
            content={t(GroupAction.LEAVE)}
          />
        )}
        {isAdmin && (
          <MenuItem
            onClick={openDeleteDialog}
            content={t(GroupAction.DELETE)}
          />
        )}
      </Menu>

      <DeleteGroupDialog
        groupId={groupId}
        onClose={closeDialog}
        isOpen={openedDialog === GroupAction.DELETE}
      />

      <GroupSettings
        groupId={groupId}
        isOpen={openedDialog === GroupAction.GROUP_SETTINGS}
        onClose={closeDialog}
      />

      <NotificationSettings
        groupId={groupId}
        isOpen={openedDialog === GroupAction.NOTIFICATION_SETTINGS}
        onClose={closeDialog}
      />

      <ShareDialog
        isOpen={openedDialog === GroupAction.SHARE}
        data={{ url: groupUrl }}
        onClose={closeDialog}
        title={t('groups-web.group.actions.share.group')}
      />
    </>
  );

  function handleOpenShareDialog() {
    setOpenedDialog(GroupAction.SHARE);

    bi.report(
      groupActionClick({
        origin: 'menu',
        action: 'share',
        group_id: groupId,
      }),
    );
  }

  function openDeleteDialog() {
    setOpenedDialog(GroupAction.DELETE);
    bi.report(
      groupActionClick({
        action: 'delete',
        group_id: groupId,
        origin: 'menu',
      }),
    );
  }

  function handleOpenLeaveDialog() {
    application$.showDialog({
      dialog: 'leaveGroup',
      params: { groupId },
    });

    bi.report(
      groupActionClick({
        action: 'leave',
        group_id: groupId,
        origin: 'menu',
      }),
    );
  }

  function closeDialog() {
    setOpenedDialog(null);
  }

  function openNotificationSettingsDialog() {
    setOpenedDialog(GroupAction.NOTIFICATION_SETTINGS);

    bi.report(
      groupActionClick({
        action: 'notification_settings',
        group_id: groupId,
        origin: 'menu',
      }),
    );
  }

  function openGroupSettingsDialog() {
    setOpenedDialog(GroupAction.GROUP_SETTINGS);

    bi.report(
      groupActionClick({
        group_id: groupId,
        action: 'group_settings',
        origin: 'menu',
      }),
    );
  }
};

GroupActions.displayName = 'GroupActions';
