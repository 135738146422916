import React from 'react';
import cls from 'classnames';
import { useSelector } from 'react-redux';
import { useBi, useEnvironment, useTranslation } from '@wix/yoshi-flow-editor';
import { groupsTabClicked } from '@wix/bi-logger-groups/v2';

import { Tabs, TabsAlignment, TabsSkin, TabsVariant } from 'wui/Tabs';

import { GroupAppKey } from 'store/groups/types';
import {
  selectGroupIdBySlug,
  selectInstalledApplications,
} from 'store/selectors';
import { useCurrentStateAndParams, useRouter } from 'router/react';

import classes from './Navigation.scss';

interface NavigationTabs<T> {
  id?: T;
  title?: string;
  href?: string;
}

export function Navigation() {
  const router = useRouter();
  const { state, params } = useCurrentStateAndParams();
  const { isRTL, isMobile, isSSR } = useEnvironment();
  const { t } = useTranslation();
  const bi = useBi();

  const groupId = useSelector(selectGroupIdBySlug(params.slug));
  const applications = useSelector(selectInstalledApplications(groupId));

  const tabs = React.useMemo<NavigationTabs<GroupAppKey>[]>(() => {
    return applications.map((application) => {
      let route = getApplicationRoute(application.key!);

      let href;
      if (
        application.key === GroupAppKey.CUSTOM_APP ||
        application.key === GroupAppKey.CUSTOM_APP_2 ||
        application.key === GroupAppKey.CUSTOM_APP_3
      ) {
        route = getApplicationRoute(GroupAppKey.CUSTOM_APP);
        if (isSSR) {
          href = router.href(
            getApplicationRoute(GroupAppKey.CUSTOM_APP)!.name as string,
            {
              ...params,
              tabId: application.key?.toLowerCase(),
            },
            { absolute: true },
          );
        }
      } else {
        if (isSSR) {
          href = router.href(
            getApplicationRoute(application.key!)!.name as string,
            params,
            { absolute: true },
          );
        }
      }

      return {
        id: application.key,
        title:
          application.customName || t(route?.data?.title, { count: undefined }),
        href,
      };
    });
  }, [isSSR, JSON.stringify(params), applications]);

  const activeTab = tabs.find((tab) => {
    if (
      tab.id === GroupAppKey.CUSTOM_APP ||
      tab.id === GroupAppKey.CUSTOM_APP_2 ||
      tab.id === GroupAppKey.CUSTOM_APP_3
    ) {
      return tab.id?.toLowerCase() === params?.tabId;
    } else {
      return tab.id === state.data?.application;
    }
  });

  return (
    <Tabs
      items={tabs}
      data-hook="tabsHook"
      aria-labelledby="group-title"
      skin={TabsSkin.clear}
      onChange={handleTabClick}
      value={activeTab?.id ?? GroupAppKey.FEED_APP}
      alignment={isRTL ? TabsAlignment.right : TabsAlignment.left}
      variant={isMobile ? TabsVariant.fullWidth : TabsVariant.fit}
      className={cls(classes.root, { [classes.mobile]: isMobile })}
    />
  );

  function getApplicationRoute(key: GroupAppKey) {
    if (
      key === GroupAppKey.CUSTOM_APP ||
      key === GroupAppKey.CUSTOM_APP_2 ||
      key === GroupAppKey.CUSTOM_APP_3
    ) {
      return router.states['group.custom.tab'];
    }

    return Object.values(router.states).find(
      (route) => route.data?.application === key,
    );
  }

  function handleTabClick(tabId: string) {
    const tab = tabs.find((tab) => tab.id === tabId);

    if (!tab) {
      return;
    }

    if (
      tab.id === GroupAppKey.CUSTOM_APP ||
      tab.id === GroupAppKey.CUSTOM_APP_2 ||
      tab.id === GroupAppKey.CUSTOM_APP_3
    ) {
      router.go('group.custom.tab', { tabId: tab?.id?.toLowerCase() });
    } else {
      router.go(getApplicationRoute(tab.id as GroupAppKey)!.name as string);
    }

    bi.report(
      groupsTabClicked({
        group_id: groupId,
        origin: 'tabs',
        name: tab.id,
      }),
    );
  }
}

Navigation.displayName = 'Navigation';
