import {
  StyleParamType,
  SettingsParamType,
  wixColorParam,
  wixFontParam,
  createStylesParam,
  createSettingsParam,
} from '@wix/tpa-settings';

import { CoverImageLayout } from '../consts';
import { doNotTransformSettingsKey, transformSettingsKey } from '../helpers';

export const settings = {
  coverImageLayout: createSettingsParam('coverImageLayout', {
    inheritDesktop: false,
    getDefaultValue: () => CoverImageLayout.large,
    dangerousKeyTransformationOverride: transformSettingsKey,
  }),
};

export const styles = {
  groupHeaderIconsColor: createStylesParam('groupHeaderIconsColor', {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5'),
  }),

  groupHeaderBackgroundColor: createStylesParam('groupHeaderBackgroundColor', {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-1'),
  }),

  groupTitleColor: createStylesParam('groupTitleColor', {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5'),
  }),

  groupTitleFont: createStylesParam('groupTitleFont', {
    type: StyleParamType.Font,
    getDefaultValue: wixFontParam('Page-title', { size: 20 }),
  }),

  mobileGroupTitleFontSize: createStylesParam('mobileGroupTitleFontSize', {
    type: StyleParamType.Number,
    getDefaultValue: () => 20,
    dangerousKeyTransformationOverride: doNotTransformSettingsKey,
  }),

  groupSubtitleColor: createStylesParam('groupSubtitleColor', {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5'),
  }),

  groupSubtitleFont: createStylesParam('groupSubtitleFont', {
    type: StyleParamType.Font,
    getDefaultValue: wixFontParam('Body-M', { size: 14 }),
  }),

  mobileGroupSubtitleFontSize: createStylesParam(
    'mobileGroupSubtitleFontSize',
    {
      type: StyleParamType.Number,
      getDefaultValue: () => 14,
      dangerousKeyTransformationOverride: doNotTransformSettingsKey,
    },
  ),

  groupBreadcrumbsColor: createStylesParam('groupBreadcrumbsColor', {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5'),
  }),

  groupBreadcrumbsFont: createStylesParam('groupBreadcrumbsFont', {
    type: StyleParamType.Font,
    getDefaultValue: wixFontParam('Body-M', { size: 16 }),
  }),

  tabsColor: createStylesParam('tabsColor', {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5'),
  }),

  tabsFont: createStylesParam('tabsFont', {
    type: StyleParamType.Font,
    getDefaultValue: wixFontParam('Body-M', { size: 16 }),
  }),

  mobileTabsFontSize: createStylesParam('mobileTabsFontSize', {
    type: StyleParamType.Number,
    getDefaultValue: () => 16,
    dangerousKeyTransformationOverride: doNotTransformSettingsKey,
  }),

  selectedTabColor: createStylesParam('selectedTabColor', {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-8'),
  }),

  dividerTabWidth: createStylesParam('dividerTabWidth', {
    type: StyleParamType.Number,
    getDefaultValue: () => 1,
  }),

  dividerTabColor: createStylesParam('dividerTabColor', {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-3'),
  }),

  coverImageHeight: createStylesParam('coverImageHeight', {
    inheritDesktop: false,
    type: StyleParamType.Number,
    getDefaultValue: ({ isMobile }) => {
      if (isMobile) {
        return 100;
      } else {
        return 240;
      }
    },
    dangerousKeyTransformationOverride: transformSettingsKey,
  }),
};
